<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex; margin: 2rem; ">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Data Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="getRegion()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="padding-left: 1rem;">
                        <label for="utility" class="form-label select-lable lable-text"> Region </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="regionId"
                            v-model="selectedRegionId" @change="regionChanged($event)">
                            <option v-for="region in regionList" v-bind:key="region.energyRegionId"
                                :value="region.energyRegionId">
                                {{ region.energyRegionName }}
                            </option>
                        </select>
                        <span style="color:red" v-if=showPlantConfigureMsg>
                            Please configure the Region in user profile.
                        </span>
                    </div>
                    <div class="plant-dd" style="padding-left: 1rem;">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            v-model="selectedPlantId" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.energyPlantId"
                                :value="plant.energyPlantId">
                                {{ plant.energyPlantName }}
                            </option>
                        </select>
                        <span style="color:red" v-if=showPlantConfigureMsg>
                            Please configure the Plant in user profile.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-with-Plant default-font-color" v-if="titleWithPlant">
            {{ titleWithPlant }}
            <div class="button-dd" v-if="energylossStatus.length !== 0">
                <div title="Export to Excel" class="download-view action-btn" @click="downloadList">
                    <img alt="download" src="@/assets/download_icon.svg" />
                </div>
            </div>
        </div>
        <div class="main-con" style="padding: 3rem 0 !important">
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-action-tracker" v-if="energylossStatus && energylossStatus.length !== 0">
                    <!-- Table for POENERGYADMIN -->
                    <b-table v-if="$oidc.userProfile.roles.includes(roles.POENERGYADMIN)" id="energyStatustbl" sticky-header
                        class="project-list-table scroll gn-table-b" responsive :items="energylossStatus"
                        :fields="energylossStatusHeader" :per-page="perPage" :current-page="currentPage">
                        <template #cell(projectId)="data">
                            <button type="button" class="delete-view action-btn" @click="deleteRow(data.item.projectId)">
                                <img alt="delete" src="@/assets/trash.svg" />
                            </button>
                        </template>
                    </b-table>

                    <!-- Table for POENERGYUSER -->
                    <b-table v-if="$oidc.userProfile.roles.includes(roles.POENERGYUSER)" id="energyStatustbl" sticky-header
                        class="project-list-table scroll gn-table-b" responsive :items="energylossStatus"
                        :fields="energylossStatusHeaderForUser" :per-page="perPage" :current-page="currentPage">
                        <template #cell(projectId)="data">
                            <button type="button" class="delete-view action-btn" @click="deleteRow(data.item.projectId)">
                                <img alt="delete" src="@/assets/trash.svg" />
                            </button>
                        </template>
                    </b-table>

                    <div v-if="energylossStatus && energylossStatus.length >= 1" class="pagination-con">
                        <div class="col-lg-3 col-md-12 col-sm-12 record-info">
                            {{ showingData(energylossStatus.length) }}
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 pagination-con">
                            <b-pagination class="pagination-align record-info" v-model="currentPage"
                                :total-rows="energylossStatus.length" :per-page="perPage" aria-controls="my-table"
                                prev-text="Back" next-text="Next" first-text="First" last-text="Last">
                            </b-pagination>
                        </div>
                        <div class="col-lg-2 col-md-12 col-sm-12 record-info text-rt">
                            <div class="per-page-cont record-info">
                                <select v-model="perPage" class="per-page" aria-label="Default select example">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option v-if="energylossStatus.length > 3 && energylossStatus.length < 6"
                                        :value="energylossStatus.length">
                                        {{ energylossStatus.length }}
                                    </option>
                                </select>
                                Per page
                            </div>
                        </div>
                    </div>
                </div>

                <b-tbody style="padding: 0rem 0rem 0rem 40rem;" v-if="energylossStatus && energylossStatus.length === 0">
                    <tr>
                        <td class="text-center">
                            <img alt="no data" src="@/assets/No_Data.svg" />
                            <div class="ed-date">No Data Found</div>
                        </td>
                    </tr>
                </b-tbody>

            </div>

            <!-- <div class="button-div" style="margin-right: 15px;">
                <b-button pill variant="outline-secondary" style="padding: 0.25rem 2rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="$router.go(-1)">
                    Cancel
                </b-button>
            </div> -->
        </div>

        <b-modal id="deleteAlert" :data-backdrop="true" :no-close-on-backdrop="true" :no-close-on-esc="true"
            class="modal-body" ref="deleteAlertMsg" hide-footer hide-header-close hide-header :visible="showDeletePopup"
            size="sm">
            <div class="col-12 no-padding page-content-regular">
                <p class="page-heading-tab" style="text-align: justify">
                    {{ deleteConfirmMsg }}
                </p>
            </div>
            <div class="button-div">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem; float: right"
                    class="btn cancel-save-btn button-sm" @click="showDeletePopup = false">
                    Ok
                </b-button>
            </div>
        </b-modal>


    </div>
</template>
<script>
import "@/common/css/CommonStyle.css";
import "@/common/css/font-categories.css";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Loader from "@/components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "@/common/constants/api/index";
import { mapGetters } from "vuex";
import XLSX from "xlsx";
import Roles from "@/common/constants/roles";
import moment from 'moment';
import oidc from '@/common/constants/oidc';
export default {
    name: "EnergyLossStatus",
    data() {
        return {
            plantId: {},
            selectedRegionName: "All",
            selectedPlantName: "All",
            showLoader: true,
            committedYr: new Date().getFullYear(),
            plantList: [],
            regionList: [],
            selectedRegionId: 10, // 10 - All
            selectedPlantId: 100, // 100 - All
            energylossStatus: [],
            summaryItems: [],
            currentPage: 1,
            perPage: 5,
            committedYear: [],
            firstYear: new Date().getFullYear() + 1,
            secondYear: new Date().getFullYear() + 2,
            thirdYear: new Date().getFullYear() + 3,
            fourthYear: new Date().getFullYear() + 4,
            fifthYear: new Date().getFullYear() + 5,
            firstYearValue: null,
            secondYearValue: null,
            thirdYearValue: null,
            fourthYearValue: null,
            fifthYearValue: null,
            roles: Roles,
            energylossStatusHeader: [
                {
                    label: "Region",
                    key: "regionId",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Plant",
                    key: "plantId",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Energy Zero Loss Category",
                    key: "zeroLossCategoryName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Process Area",
                    key: "processArea",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Potential Water Reduction",
                    key: "potentialWaterReduction",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Project Name",
                    key: "projectName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Category of Project Investment (Low/Med/High)",
                    key: "projectInvestmentCategory",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Investment ($000)",
                    key: "investment",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Cost Savings ($000)",
                    key: "costSaving",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Payback (Years)",
                    key: "payback",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "firstYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 1),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "secondYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 2),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "thirdYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 3),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "fourthYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 4),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "fifthYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 5),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Source",
                    key: "projectSourceName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Potential KWH",
                    key: "potentialValue",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Year of Renewable",
                    key: "selectedImplementationYear",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Created Date",
                    key: "createdDate",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Action",
                    key: "projectId",
                    sortable: false,
                    children: [],
                    variant: "success"
                }
            ],
            energylossStatusHeaderForUser: [
                {
                    label: "Region",
                    key: "regionId",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Plant",
                    key: "plantId",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Energy Zero Loss Category",
                    key: "zeroLossCategoryName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Process Area",
                    key: "processArea",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Potential Water Reduction",
                    key: "potentialWaterReduction",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Project Name",
                    key: "projectName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Category of Project Investment (Low/Med/High)",
                    key: "projectInvestmentCategory",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Investment ($000)",
                    key: "investment",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Cost Savings ($000)",
                    key: "costSaving",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Payback (Years)",
                    key: "payback",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "firstYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 1),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "secondYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 2),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "thirdYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 3),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "fourthYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 4),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "fifthYearValue",
                    label: 'Energy Reduction-' + (new Date().getFullYear() + 5),
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "Source",
                    key: "projectSourceName",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Potential KWH",
                    key: "potentialValue",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Created Date",
                    key: "createdDate",
                    sortable: false,
                    children: [],
                    variant: "success"
                },
                {
                    label: "Year of Renewable",
                    key: "selectedImplementationYear",
                    sortable: false,
                    children: [],
                    variant: "success"
                }
            ],
            QId: [],
            RegId: [],
            PlntId: [],
            showDeletePopup: false,
            deleteConfirmMsg: '',
            showPlantConfigureMsg: false,
            loginRole: '',
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 5,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Energy Saving - Project List",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "ProjectList", primary: false }
            ]
        });
    },
    mounted() { },
    created() {

        this.getCommittedYear();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected", "prevSelectedRegion"]),
        titleWithPlant() {
            return this.plantList.hasOwnProperty("energyPlantName") ? `Energy Loss for ${this.plantList.energyPlantName ?? " "}, ${this.plantId.energyRegionId ?? " "}` : " ";
        }
    },
    methods: {
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.PROJECTLISTYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getRegion();
            });
        },
        getRegion() {
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSREGIONS, "get").then((data) => {
                this.regionList = data.data;
                let dataLength = data.data.length
                if (dataLength !== 0) {
                    this.regionList.unshift({
                        energyRegionId: 10, // ALL 10-Unique No
                        energyRegionName: "All"
                    });
                    let rId = this.selectedRegionId;
                    if (rId == 10) {
                        this.RegId = [];
                        for (let i = 1; i < this.regionList.length; i++) {
                            this.RegId.push(this.regionList[i].energyRegionId);
                        }
                    } else {
                        this.RegId = [];
                        this.RegId.push(this.selectedRegionId);
                    }
                    this.getPlantList(this.RegId);
                } else {
                    this.showPlantConfigureMsg = true;
                    this.showLoader = false
                }
            });
        },
        getPlantList(RegId) {
            let plantPayload = {
                regionIds: RegId,
                year: this.committedYr
            }
            console.log("plantPayload", plantPayload)
            ApiCalls.httpCallDetails(Api.GETENERGYLOSSPLANTS, "post", plantPayload).then((data) => {
                if (RegId.length == 1) {
                    this.plantList = data.data.filter(function (el) {
                        return el.energyRegionId == RegId;
                    });
                    this.plantList.unshift({
                        energyPlantId: 100, // ALL 100-Unique No
                        energyPlantName: "All"
                    });
                    this.selectedPlantId = 100;
                } else {
                    this.plantList = data.data;
                    this.plantList.unshift({
                        energyPlantId: 100, // ALL 100-Unique No
                        energyPlantName: "All"
                    });
                    this.selectedPlantId = 100;
                }
                this.plantId.energyPlantId = 100;

                this.showLoader = false;
                this.filterHandler();
            });
        },
        regionChanged(event) {
            this.showLoader = true;
            this.RegId = [];
            if (event.target.value == 10) {
                for (let i = 1; i < this.regionList.length; i++) {
                    this.RegId.push(this.regionList[i].energyRegionId);
                }
            } else {
                this.RegId.push(event.target.value);
            }
            this.selectedRegionName = this.regionList.find((el) => el.energyRegionId == event.target.value).energyRegionName;
            this.getPlantList(this.RegId);
        },
        plantChanged(event) {
            this.showLoader = true;
            this.plantId = this.plantList.find((el) => el.energyPlantId == event.target.value);
            this.selectedPlantName = this.plantList.find((el) => el.energyPlantId == event.target.value).energyPlantName;
            this.filterHandler();
        },

        showingData(count) {
            let showData = this.perPage * this.currentPage;
            let showFirstData = showData - this.perPage;
            if (showData > count) {
                showData = count;
            }
            showFirstData = showFirstData + 1;
            return "Showing " + showFirstData + " to " + showData + " of " + count + " records";
        },

        filterHandler() {
            if (this.selectedRegionId == 10 && this.plantId.energyPlantId == 100) {
                this.PlntId = [];
                for (let i = 1; i < this.plantList.length; i++) {
                    this.PlntId.push(this.plantList[i].energyPlantId);
                }
            } else if (this.selectedRegionId == 1 && this.plantId.energyPlantId == 100) {
                // AP 
                this.PlntId = [];
                this.tempAP = this.plantList.filter(function (el) {
                    return el.energyRegionId == 1;
                });
                for (const element of this.tempAP) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 2 && this.plantId.energyPlantId == 100) {
                // CHEM

                this.PlntId = [];
                this.tempCHEM = this.plantList.filter(function (el) {
                    return el.energyRegionId == 2;
                });
                for (const element of this.tempCHEM) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 3 && this.plantId.energyPlantId == 100) {
                // EMEA


                this.PlntId = [];
                this.tempEMEA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 3;
                });
                for (const element of this.tempEMEA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 4 && this.plantId.energyPlantId == 100) {
                // GLOBAL


                this.PlntId = [];
                this.tempGLOBAL = this.plantList.filter(function (el) {
                    return el.energyRegionId == 4;
                });
                for (const element of this.tempGLOBAL) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 5 && this.plantId.energyPlantId == 100) {
                // LA


                this.PlntId = [];
                this.tempLA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 5;
                });
                for (const element of this.tempLA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else if (this.selectedRegionId == 6 && this.plantId.energyPlantId == 100) {
                // NA


                this.PlntId = [];
                this.tempNA = this.plantList.filter(function (el) {
                    return el.energyRegionId == 6;
                });
                for (const element of this.tempNA) {
                    this.PlntId.push(element.energyPlantId);
                }
            } else {
                this.PlntId = [];
                this.PlntId.push(this.plantId.energyPlantId);
            }
            let statusPayload = {
                plantIds: this.PlntId,
                year: this.committedYr
            }
            console.log("statusPayload", statusPayload);
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.GETPROJECTLISTSUMMARY, "post", statusPayload).then((data) => {
                this.energylossStatus = [];
                console.log("EnergylossStatus", data.data);
                data.data.forEach((el) => {
                    console.log("el", el)
                    let rName = this.regionList.filter((item) => item.energyRegionId === el.regionId);
                    console.log("rName", rName)
                    let regName = rName[0].energyRegionName;
                    let plName = this.plantList.filter((it) => it.energyPlantId === el.plantId);
                    let plntName = plName[0].energyPlantName;
                    if (el.potentialWaterReduction === "true") {
                        this.pwa = "Yes";
                    } else {
                        this.pwa = "No";
                    }
                    for (let i = 0; i < el.energyReductions.length; i++) {
                        if (this.firstYear === el.energyReductions[i].year) {
                            this.firstYearValue = el.energyReductions[i].value
                        } else if (this.secondYear === el.energyReductions[i].year) {
                            this.secondYearValue = el.energyReductions[i].value
                        } else if (this.thirdYear === el.energyReductions[i].year) {
                            this.thirdYearValue = el.energyReductions[i].value
                        } else if (this.fourthYear === el.energyReductions[i].year) {
                            this.fourthYearValue = el.energyReductions[i].value
                        } else if (this.fifthYear === el.energyReductions[i].year) {
                            this.fifthYearValue = el.energyReductions[i].value
                        }
                    }
                    if (this.$oidc.userProfile.roles.includes(this.roles.POENERGYADMIN)) {
                        this.energylossStatus.push({
                            regionId: regName,
                            plantId: plntName,
                            zeroLossCategoryName: el.zeroLossCategoryName,
                            processArea: el.processArea,
                            potentialWaterReduction: this.pwa,
                            projectName: el.projectName,
                            projectInvestmentCategory: el.projectInvestmentCategory,
                            investment: el.investment,
                            costSaving: el.costSaving,
                            payback: el.payback,
                            // doNotChangeFormula : el.doNotChangeFormula,
                            // projectId : el.projectId,
                            projectSourceName: el.projectSourceName,
                            // isActive : el.isActive,
                            // userName : el.userName,
                            potentialValue: el.potentialValue,
                            selectedImplementationYear: el.selectedImplementationYear,
                            firstYearValue: this.firstYearValue,
                            secondYearValue: this.secondYearValue,
                            thirdYearValue: this.thirdYearValue,
                            fourthYearValue: this.fourthYearValue,
                            fifthYearValue: this.fifthYearValue,
                            projectId: el.projectId,
                            createdDate: this.getFormattedDate(el.createdDate)
                        });
                    }
                    else {
                        this.energylossStatus.push({
                            regionId: regName,
                            plantId: plntName,
                            zeroLossCategoryName: el.zeroLossCategoryName,
                            processArea: el.processArea,
                            potentialWaterReduction: this.pwa,
                            projectName: el.projectName,
                            projectInvestmentCategory: el.projectInvestmentCategory,
                            investment: el.investment,
                            costSaving: el.costSaving,
                            payback: el.payback,
                            // doNotChangeFormula : el.doNotChangeFormula,
                            // projectId : el.projectId,
                            projectSourceName: el.projectSourceName,
                            // isActive : el.isActive,
                            // userName : el.userName,
                            potentialValue: el.potentialValue,
                            selectedImplementationYear: el.selectedImplementationYear,
                            firstYearValue: this.firstYearValue,
                            secondYearValue: this.secondYearValue,
                            thirdYearValue: this.thirdYearValue,
                            fourthYearValue: this.fourthYearValue,
                            fifthYearValue: this.fifthYearValue,
                            createdDate: this.getFormattedDate(this.createdDate)
                        });
                    }
                });
                this.showLoader = false;
                console.log("Summary", this.energylossStatus);
            });
        },
        getFormattedDate(date) {
            return moment(date).format("YYYY-MM-DD")
        },
        deleteRow(rowId) {
            console.log("Row Id", rowId)
            this.showLoader = true;
            ApiCalls.httpCallDetails(Api.DELETEENERGYPROJECTREC, "post", rowId).then((data) => {
                console.log("Delete", data)
                this.showDeletePopup = true;
                this.deleteConfirmMsg = "The record has been deleted successfully."
                this.filterHandler();
                this.showLoader = false;
            })
        },
        downloadList() {
            let exportExl = [];
            this.energylossStatus.forEach((e) => {
                let jsonSheet = {};
                jsonSheet["Region"] = e.regionId,
                    jsonSheet["Plant"] = e.plantId,
                    jsonSheet["Energy Zero Loss Category"] = e.zeroLossCategoryName,
                    jsonSheet["Process Area"] = e.processArea,
                    jsonSheet["Potential Water Reduction"] = e.potentialWaterReduction,
                    jsonSheet["Project Name"] = e.projectName,
                    jsonSheet["Category of Project Investment (Low/Med/High)"] = e.projectInvestmentCategory,
                    jsonSheet["Investment ($000)"] = e.investment,
                    jsonSheet["Cost Savings"] = e.costSaving,
                    jsonSheet["Payback"] = e.payback,
                    jsonSheet["Energy Reduction (BTU)-" + (new Date().getFullYear() + 1)] = e.firstYearValue,
                    jsonSheet["Energy Reduction (BTU)-" + (new Date().getFullYear() + 2)] = e.secondYearValue,
                    jsonSheet["Energy Reduction (BTU)-" + (new Date().getFullYear() + 3)] = e.thirdYearValue,
                    jsonSheet["Energy Reduction (BTU)-" + (new Date().getFullYear() + 4)] = e.fourthYearValue,
                    jsonSheet["Energy Reduction (BTU)-" + (new Date().getFullYear() + 5)] = e.fifthYearValue,
                    jsonSheet["Fuel Source"] = e.projectSourceName,
                    jsonSheet["Potential KWH from Purchased or Generated Renewable Electricity"] = e.potentialValue,
                    jsonSheet["Year of Renewable Energy Implementation"] = e.selectedImplementationYear
                jsonSheet["Created Date"] = this.getFormattedDate(e.createdDate)
                exportExl.push(jsonSheet);
            })
            let sheet = XLSX.utils.json_to_sheet(exportExl);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, sheet, "Project-List");
            XLSX.writeFile(wb, "Project List.xlsx");
        },

        goBack() {
            this.$router.push("/energy-loss-project-list");
        }
    }
};
</script>
<style scoped>
.b-table-sticky-header {
    max-height: 400px !important;
}

.download-view {
    border-radius: 2rem;
    border: 1px solid #274ba7;

    font-size: 1.33rem;
}

.delete-view {
    border-radius: 2rem;
    border: 1px solid orangered;

    font-size: 1.33rem;
}

.download-view:hover {
    background: #e3e8f7;
}

.button-dd {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;

}

.action-btn {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
}

.pagination {
    margin: 0 !important;
}

.pagination-con {
    display: flex;
    align-items: center;
    justify-content: center;
}

.per-page-count {
    text-align: end;
}

.plant-dd {
    width: 20.5%;
}

.main-con {
    background: #fff;
    margin-top: 0em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.reload-img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

div.fileinputs {
    position: relative;
    border: 1px solid #313131;
    border-radius: 4px;
    width: 100%;
}

div.fakefile {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}

.fakefileinput {
    height: 100%;
    width: 80%;
    border: transparent;
    border-right: 1px solid #313131;
    color: #313131;
    font: 1.166rem "BarlowR", sans-serif;
    padding: 5px;
    margin: 2px;
}

.fakefilespan {
    text-align: center;
    width: 20%;
    font: 1.166rem "BarlowSb", sans-serif;
    color: #274ba7;
}

input.file {
    position: relative;
    text-align: right;
    width: 100%;
    /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
    opacity: 0;
    z-index: 2;
}

.action-info-block {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.no-padding {
    padding: 0;
}

.lable-text {
    z-index: 2;
}

.upload-button-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.pagination-con {
    display: flex;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.action-desc {
    border: #313131 1px solid;
    border-radius: 4px;
    padding: 1rem;
    width: 90%;
    resize: none;
}

.calender-input {
    height: 52px;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* document.querySelector("#history-table-id > thead > tr > th:nth-child(1)") */
#history-table-id {
    border: 0px solid white !important;
    border-top: 1px solid #707070 !important;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1365px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}

@media only screen and (min-width: 1921px) {
    .calender-input {
        height: 52px;
        font-size: 1.166rem;
        font-family: "BarlowR", sans-serif;
    }
}
</style>
